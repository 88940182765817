import axios from "axios";

export default {
  data: () => ({
    report_url: "https://reports.annuitycheck.com",
    // report_url: "http://192.168.1.246:5000",
  }),
  methods: {
    async download_blob(dl_url, type, data, name) {
      let response;
      if (type == "post") {
        response = await axios.post(dl_url, data, {
          "Content-Type": "application/json",
          responseType: "blob",
        });
      } else {
        response = await axios.get(dl_url, { responseType: "blob" });
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async aws_presigned_post(foldername, filename) {
      // TODO: Refactor this to use hasura graphql
      let { data } = await axios.post(
        "https://api-v3.annuitycheck.com/hasura/aws_presigned_post",
        {
          input: { foldername, filename },
        }
      );
      console.log(data);
      return data;
    },
    async dl_event_list(event_id) {
      // TODO: Refactor this to use hasura graphql

      await this.download_blob(
        `https://api-v3.annuitycheck.com/csvs/event_list/${event_id}`,
        "get",
        {},
        `event_${event_id}.xlsx`
      );
    },
    async dl_calculations(data) {
      await this.download_blob(
        this.report_url + `/reports/illustration`,
        // "http://172.24.77.94:5000/reports/illustration",
        "post",
        data,
        "Report.pdf"
      );
    },
    async dl_snapshot(data) {
      await this.download_blob(
        this.report_url + `/reports/snapshot`,
        "post",
        data,
        "Snapshot.pdf"
      );
    },
    async overflow_list(seminar_id) {
      await this.download_blob(
        this.report_url + `/deliverables/overflow_list`,
        "post",
        { seminar_id: seminar_id },
        "Overflow List.pdf"
      );
    },
    async mailer(seminar_id) {
      await this.download_blob(
        `https://templates.annuitycheck.com/seminar_helper/${seminar_id}`,
        "get",
        {},
        "Mailer.pdf"
      );
    },
    async OLD_registration_cards(seminar_id, event_id, time_slots) {
      // This is what we are fixing
      await this.download_blob(
        this.report_url + `/deliverables/registration_cards`,
        "post",
        {
          seminar_id,
          event_id,
          time_slots,
        },
        "Appointment Cards.pdf"
      );
    },
    async registration_cards(data) {
      await this.download_blob(
        `https://templates.annuitycheck.com/appointment_card`,
        // `http://127.0.0.1:5000/appointment_card`,
        "post",
        data,
        "Appointment Cards.pdf"
      );
    },

    async toons_cards(data) {
      await this.download_blob(
        `https://templates.annuitycheck.com/toon_helper`,
        "post",
        data,
        "Followup.pdf"
      );
    },
    async registration_list(seminar_id, event_id) {
      await this.download_blob(
        this.report_url + "/deliverables/registration_list",
        "post",
        {
          seminar_id,
          event_id,
        },
        "Registration List.pdf"
      );
    },
    // async report_download(data) {},
    async get_accudata_zip(zips, income, minage, maxage, ethnicity, ipa) {
      // Data should look like this:
      // {"zips":["85201"],"income":"75000","distance":"10","minage":"60","maxage":"75"}
      // console.warn(zips, income, minage, maxage);
      // return {
      //   data: {
      //     count: 1,
      //     request_id: 1,
      //   },
      // };

      return await axios.post(
        "https://accudata.annuitycheck.com/misc/accudata/count_zip",
        // "http://34.212.224.86:5010/misc/accudata/count_zip",
        {
          zips,
          income,
          distance: 0, // distance
          minage,
          maxage,
          ethnicity,
          ipa,
        }
      );
    },
    async get_accudata_radius(
      lat,
      lng,
      distance,
      income,
      minage,
      maxage,
      ethnicity,
      ipa
    ) {
      console.log(lat, lng, distance, income, minage, maxage, ethnicity, ipa);
      return await axios.post(
        "https://accudata.annuitycheck.com/misc/accudata/count_radius",
        // "http://34.212.224.86:5010/misc/accudata/count_radius",
        {
          lat,
          lng,
          distance,
          income,
          minage,
          maxage,
          ethnicity,
          ipa,
        }
      );
    },
  },
};
