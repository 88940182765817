<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        outlined
        color="primary"
        class="my-3 btn-fix"
        :disabled="campaign.households.length === 0"
        style="width: 80%"
      >
        <v-icon>mdi-file-pdf-box</v-icon> Appointment Cards
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Appointment Cards</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pb-0">
            <v-text-field
              dense
              label="Speaker Name"
              v-model="speaker_name"
              width="100%"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-text-field
              dense
              label="Advisor Name"
              v-model="advisor_name"
              width="100%"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pb-0">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  :value="formatDate(aptDate1)"
                  label="Appointment Date 1"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="req"
                  width="100%"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="date"
                v-model="aptDate1"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  :value="formatDate(aptDate2)"
                  label="Appointment Date 2"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="req"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="date"
                v-model="aptDate2"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  :value="formatDate(aptDate3)"
                  label="Appointment Date 3"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="req"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="date"
                v-model="aptDate3"
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-menu
              v-model="menu4"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  :value="formatDate(aptDate4)"
                  label="Appointment Date 4"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="req"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="date"
                v-model="aptDate4"
                @input="menu4 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-switch
              v-model="showOffice"
              label="Show Office Address"
              color="primary"
              class="mt-3"
            ></v-switch>
            <div v-if="showOffice">
              <v-text-field
                v-model="office"
                label="Office Address"
                counter="46"
                class="mt-3"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="download_regcard"
          :loading="regCardLoading"
        >
          Generate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>

<script>
import axiosService from "@/services/axios.service.js";
import dayjs from "dayjs";

export default {
  name: "HouseholdDetail",
  mixins: [axiosService],
  props: {
    event: Object,
    seminar_id: Number,
    campaign: Object,
  },

  mounted() {
    this.advisor_name =
      this.campaign.user.first_name + " " + this.campaign.user.last_name;
    this.speaker_name =
      this.campaign.user.first_name + " " + this.campaign.user.last_name;
    this.aptDate1 = this.nextBusinessDay(dayjs()).format("YYYY-MM-DD");
    this.aptDate2 = this.nextBusinessDay(this.aptDate1).format("YYYY-MM-DD");
    this.aptDate3 = this.nextBusinessDay(this.aptDate2).format("YYYY-MM-DD");
    this.aptDate4 = this.nextBusinessDay(this.aptDate3).format("YYYY-MM-DD");
    this.setup_reg_card();
  },
  methods: {
    titleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    setup_reg_card() {
      this.final_data = [];
      for (let index = 0; index < this.campaign.households.length; index++) {
        const household = this.campaign.households[index];
        if (this.event.id !== household.event_id) {
          continue;
        }
        // advisor_office
        this.final_data.push({
          advisor_office: this.showOffice ? this.office : null,
          advisor_name: this.advisor_name,
          speaker_name: this.speaker_name,
          advisor_phone: this.campaign.user.phone,
          advisor_email: this.campaign.user.email,
          seminar_date: dayjs(this.event.start_date).format("MMM DD, YYYY"),
          day_1: dayjs(this.aptDate1).format("dddd"),
          date_1: dayjs(this.aptDate1).format("M/D"),
          day_2: dayjs(this.aptDate2).format("dddd"),
          date_2: dayjs(this.aptDate2).format("M/D"),
          day_3: dayjs(this.aptDate3).format("dddd"),
          date_3: dayjs(this.aptDate3).format("M/D"),
          day_4: dayjs(this.aptDate4).format("dddd"),
          date_4: dayjs(this.aptDate4).format("M/D"),
          client_name_1: household.people[0].name,
          client_name_2:
            household.people.length > 1
              ? household.people[1].name == null ||
                household.people[1].name == ""
                ? "Spouse"
                : household.people[1].name
              : "",
          client_address:
            household.street +
            " " +
            household.city +
            " " +
            household.state +
            " " +
            household.zip,
          client_phone: household.phone,
          client_email: household.email,
        });
      }
    },
    async download_regcard() {
      this.regCardLoading = true;
      this.setup_reg_card();

      await this.registration_cards(this.final_data);
      this.regCardLoading = false;
    },

    nextBusinessDay(date) {
      let day = dayjs(date).add(1, "day");
      let weekday = day.format("dddd");
      if (weekday === "Saturday") {
        return day.add(2, "day");
      } else if (weekday === "Sunday") {
        return day.add(1, "day");
      } else {
        return day;
      }
    },
    formatDate(date) {
      let d = dayjs(date).format("dddd, MM/DD");
      if (d === "Invalid Date") {
        return "";
      }
      return d;
    },
  },
  data() {
    return {
      dialog: false,
      regCardLoading: false,
      advisor_name: "",
      speaker_name: "",
      req: [(v) => !!v || "Field is required"],
      valid: true,
      showOffice: false,
      office: "",
      final_data: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      calendar1: "",
      calendar2: "",
      calendar3: "",
      calendar4: "",
      events: [],
      aptDate1: "",
      aptDate2: "",
      aptDate3: "",
      aptDate4: "",
      date: dayjs().format("YYYY-MM-DD"),
    };
  },
};
</script>
