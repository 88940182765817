<template>
  <v-container>
    <v-form ref="form">
      <v-card v-if="!loading" elevation="4" class="mx-auto pa-5 my-5">
        <v-row>
          <v-col cols="12" class="mt-3">
            <v-card-title>
              Campaign Details

              <v-spacer></v-spacer>
              <toon-card-dialog></toon-card-dialog>

              <v-menu offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Campaign Actions <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn color="primary" @click="download_mailer" text>
                      Download Mailer
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn color="primary" @click="saveClipboard" text>
                      Copy Seminar Invite
                    </v-btn>
                  </v-list-item>

                  <v-list-item>
                    <v-btn color="primary" @click="download_overflow" text>
                      Download Overflow List
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
          </v-col>
        </v-row>
        <v-card-text>
          <h3>{{ campaign.venue_name }}</h3>
          <br />
          {{ campaign.street }}, {{ campaign.city }}, {{ campaign.state }}
          {{ campaign.zip }} <br />
          Mail Date: {{ sendingDate(campaign) }} | Status:
          <span class="titlecase">{{ campaign.status }}</span> | Registrations:
          {{ campaign.registered }}/{{ campaign.requested_count }}
          <br />
          <p style="display: none; font-weight: bold; margin-bottom: 0px" v-if="campaign.automation">
            Book and Perf Card Tracking:
            <a target="_blank" v-if="campaign.book_tracking_num"
              :href="`https://www.fedex.com/fedextrack/?tracknumbers=${campaign.book_tracking_num}`">
              Fedex
            </a>
            <span v-else>Not yet shipped</span>
          </p>
        </v-card-text>
        <v-card-text>
          <v-row class="mb-4">
            <event-table :seminar_id="this.campaign.id" :events="campaign.events" :campaign="campaign"></event-table>
          </v-row>
          <v-row>
            <household-table :households="campaign.households" :campaign="campaign"></household-table>
          </v-row>
        </v-card-text>
      </v-card>
      <v-skeleton-loader v-else type="card-avatar, article, actions"></v-skeleton-loader>
    </v-form>
  </v-container>
</template>

<style scoped>
.titlecase {
  text-transform: capitalize;
}
</style>

<script>
import axiosService from "@/services/axios.service.js";

import EventTable from "@/components/Seminar/EventTable.vue";
import HouseholdTable from "@/components/Seminar/HouseholdTable.vue";
import ToonCardDialog from "@/components/Seminar/ToonCardDialog.vue";
import hasuraService from "@/services/hasura.service.js";
import dayjs from "dayjs";
import * as copy from "copy-to-clipboard";

export default {
  name: "SeminarDetails",
  mixins: [hasuraService, axiosService],
  async mounted() {
    this.loading = true;
    this.campaign = await this.campaignById(this.$route.params.id);
    this.campaign.events = this.campaign.events.map((e) => {
      return {
        ...e,
        date: this.formatDate(e.start_date) + " " + e.start_time,
        count: e.households.reduce((a, b) => {
          return a + b.people_aggregate.aggregate.count;
        }, 0),
      };
    });
    this.campaign.registered = this.campaign.events.reduce(
      (currentVal, event) => {
        return currentVal + event.count;
      },
      0
    );
    this.loading = false;
  },

  components: {
    EventTable,
    HouseholdTable,
    ToonCardDialog,
  },

  data() {
    return {
      valid: true,
      campaign: null,
      loading: true,
      overflowLoading: false,
      mailerLoading: false,
    };
  },

  methods: {
    async setToonReady() {
      // First we need to verify that at least 1 household has been marked as appointment.
      // If less than 10% of households are not marked, we should warn the user.
      const households = this.campaign.households.filter(
        (h) => h.status === "appointment"
      );
      const total = this.campaign.households.length;
      const ready = households.length;
      const percent = (ready / total) * 100;
      if (percent === 0) {
        this.$store.commit(
          "set_snackbar",
          "You must first mark households as appointments."
        );
        return;
      }
      if (percent < 10) {
        // Warn the user
        let result = confirm(
          `You have only marked ${ready} out of ${total} households as having an appointment. Are you sure you want to order the follow up cards? You can only order follow up cards once per campaign.`
        );
        if (!result) {
          return;
        }
      }
      this.campaign.osi_toon_ready = true;
      await this.updateCampaignToonReady(this.campaign.id, true);
      this.$store.commit(
        "set_snackbar",
        "Order has been processed, you should receive the follow-up cards within 7 business days."
      );
    },
    saveClipboard() {
      let eevents = "";
      let e_lnth = this.campaign.events.length - 1;

      this.campaign.events.forEach((event, idx) => {
        eevents += `${this.formatDate(event.start_date)} at ${event.start_time
          }`;
        if (idx != e_lnth) eevents += " or ";
      });

      let phone = this.campaign.user.phone.replace(/\D/g, "");
      if (phone.length != 10) {
        phone = this.campaign.user.phone;
      } else {
        phone =
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " - " +
          phone.substring(6, 10);
      }

      let data = `Due to a lot of changes in the market, I recently scheduled an educational seminar-workshop it looks like you could be a good fit for. It is called:

THE TOP FIVE WAYS TO INCREASE ANNUITY RESULTS.

The dinner is going to be at ${this.campaign.venue_name}, ${this.campaign.street} ${this.campaign.city} ${this.campaign.state} ${this.campaign.zip} on ${eevents}

We will be open the doors about 30 minutes prior to the start time to get everyone seated, take orders, and serve the salads. This is designed to get you up to speed on how these contracts work and discuss the “good, the bad, and the ugly” as it relates to the various options.

Specifically, I plan to cover:

      • Compounding Fees: Discover the 3 Hidden, Compounding Annuity Fees (and where to find them) that May Reduce Your Growth by up to 37%, even In a Bull Market.
      • The Power of Zero: How Smart Retirees Are Contractually Locking In Annual Gains, Regardless of Long-Term Volatility Using the Power of Zero.
      • Maximum Growth: Learn about a New Growth Participation Strategy that May Increase Your Annuity Gains by up to $200,000 or More, In 10 Years or Less.
      • Guaranteed Income: See the Math that Shows Why Most Annuity Income Guarantees Result in a Lower Lifetime Return, Even When the Contract Works as Advertised.
      • Increasing Income: Learn How to Safely Increase Annual Income and Generate up to 90% More Over Your Lifetime, Without Sacrificing Your Current Lifestyle.

If you are contemplating the best way to maximize your retirement income, this workshop is definitely worth your time. If you currently own annuities or are currently evaluating them, this workshop information is imperative for your future lifestyle.

Here is the link to register: https://seminarinvite.com/seminar/${this.campaign.id}

If you have any questions, give me a call at ${phone} or reply back to this email.

Regards,

${this.campaign.user.first_name} ${this.campaign.user.last_name}
${phone}

p.s. I am using a new software called AnnuityCheck that gives us the ability to run a performance snapshot on your current annuities. This software is fully integrated with Morningstar and tracks over 3,600 company and product combinations. The report is something the carriers simply don’t provide and is essential for proper annuity planning.  If for some reason you can’t attend, let me know and we can still connect and run the snapshot on any of your current annuities.  The report only takes 5-8 minutes and we can provide a copy for your records.`;
      console.log(data);
      copy(data);
      this.$store.commit("set_snackbar", "Copied to clipboard");
    },

    async download_overflow() {
      this.overflowLoading = true;
      await this.overflow_list(this.campaign.id);
      this.overflowLoading = false;
    },
    async download_mailer() {
      this.mailerLoading = true;
      await this.mailer(this.campaign.id);
      this.mailerLoading = false;
    },

    sendingDate(campaign) {
      if (campaign.mail_date) {
        return this.formatDate(campaign.mail_date);
      }
      return dayjs(campaign.events[0].start_date)
        .subtract(17, "day")
        .format("M/D/YY");
    },

    formatDate(date) {
      return dayjs(date).format("M/D/YY");
    },
  },
};
</script>
