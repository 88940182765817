<template>
  <v-card flat>
    <v-card-text>
      <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          v-for="(item, i) in timelineItems"
          :key="i"
          :color="item.color"
          fill-dot
        >
          <v-card>
            <v-card-title class="text-h6">
              {{ item.title }}
            </v-card-title>
            <v-card-subtitle>
              {{ item.subtitle }}
            </v-card-subtitle>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import dayjs from "dayjs";

export default {
  name: "HouseholdDetail",
  mixins: [hasuraService],
  mounted() {},
  watch: {},
  computed: {
    timelineItems() {
      return [
        {
          color: this.campaign.mail_date ? "green" : "grey",
          title: "Mailed",
          subtitle: dayjs(this.campaign.mail_date).format("MMM D, YYYY"),
        },
        {
          color: "green",
          title: "Registration",
          subtitle: dayjs(this.household.created).format("MMM D, YYYY"),
        },
        {
          color:
            dayjs(this.household.event.start_date) < dayjs() ? "green" : "grey",
          title: "Seminar Reminders",
          subtitle:
            dayjs(this.household.event.start_date) < dayjs()
              ? dayjs(this.household.event.start_date).format("MMM D, YYYY")
              : "",
        },
        {
          color:
            dayjs(this.household.event.start_date) < dayjs() ? "green" : "grey",
          title: "Seminar",
          subtitle: dayjs(this.household.event.start_date).format(
            "MMM D, YYYY"
          ),
        },
        {
          color: this.household.status === "appointment" ? "green" : "grey",
          title: "Appointment Set",
          subtitle: "",
        },
        {
          color: this.household.email1_sent ? "green" : "grey",
          title: "Recap Fees",
          subtitle: this.household.email1_sent
            ? dayjs(this.household.email1_sent).format("MMM D, YYYY")
            : "",
        },
        {
          color: this.household.email2_sent ? "green" : "grey",
          title: "Recap Volatility",
          subtitle: this.household.email2_sent
            ? dayjs(this.household.email2_sent).format("MMM D, YYYY")
            : "",
        },
        {
          color: this.household.email3_sent ? "green" : "grey",
          title: "Recap Riders",
          subtitle: this.household.email3_sent
            ? dayjs(this.household.email3_sent).format("MMM D, YYYY")
            : "",
        },
        {
          color: this.household.email4_sent ? "green" : "grey",
          title: "Recap Surrender",
          subtitle: this.household.email4_sent
            ? dayjs(this.household.email4_sent).format("MMM D, YYYY")
            : "",
        },
        {
          color: this.household.email5_sent ? "green" : "grey",
          title: "Recap Caps",
          subtitle: this.household.email5_sent
            ? dayjs(this.household.email5_sent).format("MMM D, YYYY")
            : "",
        },
      ];
    },
  },
  methods: {},

  props: {
    household: Object,
    campaign: Object,
  },
  data() {
    return {};
  },
};
</script>
