<template>
  <v-data-table
    :headers="householdHeaders"
    :items="households"
    @click:row="clicked"
    style="width: 100%"
    class="elevation-2 row-pointer"
    hide-default-footer
    disable-pagination
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Households</v-toolbar-title>
        <v-spacer></v-spacer>
        <new-household-dialog :campaign="campaign"></new-household-dialog>
      </v-toolbar>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.email="{ item }">
      <!-- TODO: Fix email column width while in tablet mode if email is very long -->
      <div style="overflow-wrap: break-word">{{ item.email }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.phone="{ item }">
      {{ formatPhoneNumber(item.phone) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.lead_type="{ item }">
      <div class="titlecase">{{ item.lead_type }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.status="{ item }">
      <div class="titlecase">{{ item.status }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.clientname="{ item }">
      <div v-for="(person, index) in item.people" :key="index">
        <div v-if="person.primary || item.people.length == 1">
          {{ person.name }}
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.spousename="{ item }">
      <div v-for="(person, index) in item.people" :key="index">
        <div v-if="!person.primary && item.people.length == 2">
          {{ person.name || "Spouse" }}
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.event_start_date="{ item }">
      {{
        item.event && item.event.start_date
          ? formatDate(item.event.start_date)
          : "Overflow"
      }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit_btn="{ item }">
      <edit-household-dialog
        :household="item"
        :campaign="campaign"
      ></edit-household-dialog>
    </template>
  </v-data-table>
</template>

<style scoped>
.titlecase {
  text-transform: capitalize;
}
</style>

<script>
import EditHouseholdDialog from "./EditHouseholdDialog.vue";
import NewHouseholdDialog from "./NewHouseholdDialog.vue";
import dayjs from "dayjs";

export default {
  name: "HouseholdTable",

  components: {
    EditHouseholdDialog,
    NewHouseholdDialog,
  },
  props: {
    households: Array,
    campaign: Object,
  },
  mounted() {
    // flatten the event.start_date to event_start_date
    for (let index = 0; index < this.households.length; index++) {
      const element = this.households[index];
      this.households[index].event_start_date =
        element?.event?.start_date || "";

      this.households[index].clientname = element.people
        .filter((person) => person.primary)
        .map((person) => person.name);

      this.households[index].spousename = element.people
        .filter((person) => !person.primary)
        .map((person) => person.name);
    }
  },

  data() {
    return {
      expanded: [],
      householdHeaders: [
        {
          text: "Client Name",
          value: "clientname",
        },
        { text: "Spouse Name", value: "spousename" },
        { text: "Email", value: "email", width: "15%" },
        { text: "Phone", value: "phone" },
        { text: "Status", value: "status" },
        { text: "Lead Type", value: "lead_type" },
        { text: "Registered", value: "created" },
        { text: "Event Attending", value: "event_start_date" },
        { text: "Edit", value: "edit_btn", sortable: false },
      ],

      tab: null,
      tabitems: ["Household Details", "Notes"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  methods: {
    clicked(value) {
      if (this.expanded.length == 0) {
        this.expanded.push(value);
      } else if (this.expanded.length == 1) {
        if (this.expanded[0] == value) {
          this.expanded = [];
        } else {
          this.expanded = [];
          this.expanded.push(value);
        }
      }
    },
    formatDate(date) {
      return dayjs(date).format("M/D/YY");
    },
    formatPhoneNumber(phoneNumberString) {
      if (!phoneNumberString) {
        return "";
      }
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumberString;
    },
  },
};
</script>
