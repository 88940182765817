<template>
  <v-dialog v-model="dialog" max-width="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon class="btn-fix">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Household <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"> <v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs color="black" slider-color="primary">
          <v-tab> Household Details </v-tab>
          <v-tab> Timeline </v-tab>
          <v-tab-item>
            <v-card flat class="mt-2 mb-2">
              <v-card-text>
                <household-detail :household="household"></household-detail>
                <household-notes :household="household"></household-notes>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <household-timeline
              :household="household"
              :campaign="campaign"
            ></household-timeline>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
<script>
import HouseholdDetail from "./HouseholdDetail.vue";
import HouseholdNotes from "./HouseholdNotes.vue";
import HouseholdTimeline from "./HouseholdTimeline.vue";

export default {
  name: "HouseholdDialog",
  props: {
    household: Object,
    campaign: Object,
  },
  components: {
    HouseholdDetail,
    HouseholdTimeline,
    HouseholdNotes,
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
