<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="btn-fix"> New Household </v-btn>
    </template>

    <v-card>
      <v-card-title>New Household</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :loading="eventLoading"
                dense
                v-model="household.event_id"
                :items="eventAttendingItems"
                item-text="label"
                item-value="event_id"
                label="Event Attending"
                placeholder="Select"
                :rules="req"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                dense
                v-model="household.status"
                :items="seminarStatusItems"
                label="Status"
                placeholder="Select"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <div v-for="(person, index) in household.people" :key="index">
                <div v-if="person.primary">
                  <v-text-field
                    dense
                    v-model="person.name"
                    label="First and Last Name"
                    type=""
                    :rules="req"
                  ></v-text-field>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" v-if="household.people.length == 2">
              <div v-for="(person, index) in household.people" :key="index">
                <div v-if="!person.primary">
                  <v-text-field
                    dense
                    v-model="person.name"
                    label="Spouse First and Last Name"
                    type=""
                    :rules="req"
                  >
                    <v-tooltip bottom slot="append">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="removePerson"
                          slot="append"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-minus-circle-outline
                        </v-icon>
                      </template>
                      <span>Remove Spouse</span>
                    </v-tooltip>
                  </v-text-field>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" v-else>
              <v-tooltip bottom slot="append">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="addPerson"
                    slot="append"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Add Spouse</span>
              </v-tooltip>
              Add Spouse
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                v-model="household.phone"
                label="Phone Number"
                type="tel"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="household.email"
                label="E-mail"
                dense
                placeholder="E-mail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                v-model="household.street"
                label="Address Line"
                autocomplete="shipping street-address"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                v-model="household.city"
                label="City"
                autocomplete="shipping locality"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="household.state"
                :items="all_states"
                label="State"
                item-value="abbreviation"
                item-text="name"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                v-model="household.zip"
                label="ZIP / Postal Code"
                autocomplete="shipping postal-code"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col offset-sm="3" sm="9" class="text-right">
              <v-btn
                class="mb-5 action-btn"
                color="primary"
                @click="update"
                :loading="loading"
              >
                Create
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
<script>
import hasuraService from "@/services/hasura.service.js";
import dayjs from "dayjs";

export default {
  name: "NewHouseholdDialog",
  mixins: [hasuraService],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getEvents();
  },
  watch: {
    event_id: function (newval) {
      this.household.campaign_id = this.eventAttendingItems.filter(
        (item) => item.event_id == newval
      )[0].campaign_id;
    },
  },
  computed: {
    event_id: {
      get() {
        return this.household.event_id;
      },
      set(value) {
        this.household.event_id = value;
      },
    },
  },
  methods: {
    async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.household.lead_type = "admin";
        this.household.user_id = this.campaign.user.id;
        // create household
        let household_id = await this.insert_household(this.household);
        // add people
        for (let person of this.household.people) {
          this.insert_person(household_id, person);
        }

        // notify success

        this.$store.commit("set_snackbar", "Household created successfully");

        this.loading = false;
      } else {
        this.$store.commit(
          "set_snackbar",
          "Please fill in all required fields"
        );
      }
    },
    getEvents() {
      this.eventLoading = true;
      this.getEventsbyUser(this.campaign.user.id).then((response) => {
        this.eventLoading = false;
        this.eventAttendingItems = response
          .map((event) => {
            return {
              event_id: event.event_id,
              campaign_id: event.campaign_id,
              start_date: event.start_date,
              label: `${this.formatDate(event.start_date)} - ${
                event.venue_name
              }`,
            };
          })
          .sort((a, b) => {
            return dayjs(a.start_date).isBefore(b.start_date) ? 1 : -1;
          });
      });
    },
    formatDate(date) {
      return dayjs(date).format("M/D/YY");
    },
    addPerson() {
      this.household.people.push({
        name: "",
        primary: false,
      });
    },
    removePerson() {
      let person = this.household.people[this.household.people.length - 1];
      if (person.id) {
        this.delete_person_by_pk(person.id);
      }
      this.household.people.pop();
    },
  },
  data() {
    return {
      household: {
        event_id: null,
        status: "registered",
        phone: "",
        email: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        people: [
          {
            name: "",
            primary: true,
          },
        ],
      },
      dialog: false,
      seminarStatusItems: [
        { text: "Appointment", value: "appointment" },
        { text: "Registered", value: "registered" },
        { text: "Overflow", value: "overflow" },
      ],
      eventAttending: "",
      eventAttendingItems: [],
      loading: false,
      eventLoading: false,
      req: [(v) => !!v || "Field is required"],
      valid: true,
    };
  },
};
</script>
