<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="btn-fix" v-bind="attrs" v-on="on" style="margin-right: 5px">
        Follow Up Cards
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Follow Up Cards</v-card-title>
      <v-card-text>
        Generate a list of follow up cards for clients. Print these on the
        cardstock shipped to you along with your books.
        <v-row>
          <v-col class="pb-0" cols="6">
            <v-select
              v-model="toon"
              :items="toonTypes"
              label="Follow Up Card"
              :rules="[(v) => !!v || 'Please select a follow up card']"
              dense
              outlined
              class="mt-4"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="state"
              :items="all_states"
              label="State"
              item-value="abbreviation"
              item-text="name"
              outlined
              dense
              class="mt-4"
            ></v-select>
          </v-col>
        </v-row>

        <h3 style="margin: 10px 0 10px 0">Client Names:</h3>
        <v-row>
          <v-col
            v-for="(name, index) in names"
            :key="index"
            class="pb-0"
            cols="6"
          >
            <v-text-field
              label="Client Name"
              v-model="names[index]"
              style="margin-bottom: 0px;"
              outlined
              dense
              required
              append-icon="mdi-close"
              @click:append="names.splice(index, 1)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="names.push('')" style="margin-top: 10px;">
          <v-icon>mdi-plus</v-icon> Add another
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn color="primary" @click="download_toon" :loading="loading">
          Generate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
::v-deep .v-text-field__details {
  display: none !important;
}
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>

<script>
import axiosService from "@/services/axios.service.js";

export default {
  name: "FollowUpDialog",
  mixins: [axiosService],
  props: {
    campaign: Object,
  },

  watch: {
    dialog: {
      handler() {
        this.names = [""];
      },
      immediate: false,
    },
  },

  mounted() {},
  methods: {
    async download_toon() {
      this.loading = true;

      let lst = [];
      this.names.forEach((name) => {
        lst.push({
          name: name,
          state: this.state,
          gender: "male",
          first_name: this.$store.state.user.first_name,
          last_name: this.$store.state.user.last_name,
          phone: this.$store.state.user.phone,
          email: this.$store.state.user.email,
          toon: this.toon,
        });
      });

      await this.toons_cards(lst);

      this.loading = false;
    },
  },
  data() {
    return {
      dialog: false,
      req: [(v) => !!v || "Field is required"],
      valid: true,
      loading: false,
      names: [""],
      state: "",
      toon: "",
      toonTypes: [
        { text: "Intelligent Life", value: "toon_1" },
        { text: "Museum Painting", value: "toon_2" },
        { text: "Phone Booth", value: "toon_3" },
      ],
    };
  },
};
</script>
